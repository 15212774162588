import React, { lazy, Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Body from './Body';
const ClubDetailsHindi = lazy(() => import('./components/ClubDetailsHindi/ClubDetailsHindi'));
const ClubDetails = lazy(() => import('./components/ClubDetails/ClubDetails'));

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Body />,
    children: [
      {
        path: "/",
        element: <Suspense fallback={<></>}><ClubDetails /></Suspense>,
      },
      {
        path: "/hi",
        element: <Suspense fallback={<></>}><ClubDetailsHindi /></Suspense>,
      }
    ],
  },
]);

const App = () => {

  return (
    <div className="w-screen">
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;